export class Restaurant {
    constructor(public id: number,
                public name: string,
                public address: string,
                public phone: string){}
}

export class Employee {
    constructor(public id: number,
                public image: string,
                public firstName: string,
                public lastName: string,
                public middleName: string,
                public email: string,
                public phone: string,
                public addresses: Address[], 
                public position: Position){}
}

//['General Manager','Assistant Manager'] ... https://aptito.com/blog/restaurant-positions-and-descriptions
export class Position {
    constructor(public id: number,
                public name: string){}
}

export class Address {
    constructor(public id: number,
                public country: Country, 
                public city: string,
                public place: string,
                public postalCode: string,
                public addressLine: string){}
}

export class Country {
    constructor(public name: string,
                public code: string){}
}

export class Customer {
    constructor(public id: number,
                public fullName: string, 
                public email: string,
                public phoneNumber: string,
                public address: string){}
}

export class Reservation {
    constructor(public id: number,
                public date: string,
                public time: string,
                public customer: Customer,
                public guests: number,
                public tableNumber: number,
                public status: ReservationStatus){}
} 

// Approved, Cancelled, Pending
export class ReservationStatus {
    constructor(public id: number,
                public name: string){}
}
 

export class Order {
    constructor(public id: number,
                public date: string,
                public items: MenuItem[],
                public quantity: number,
                public amount: number,
                public status: OrderStatus){}
}  

//Completed, Processing, On Hold, Refunded, Pending
export class OrderStatus {
    constructor(public id: number,
                public name: string){}
}

export class Vessel {
    constructor(
        public id: number,
        public code: string,
        public name: string
    ){}
}

export class Port {
    constructor(
        public id: number,
        public code: string,
        public name: string
    ){}
}

export class Trip {
    constructor(public id: number,
                public code: string,
                public status: string,
                public location: string,
                public departure_date: string,
                public arrival_date: string,
                public vesselId: number,
                public originPortId: number,
                public destinationPortId: number,
                public createdAt: string,
                public updatedAt: string,
                public vessel: Vessel,
                public originPort: Port,
                public destinationPort: Port,
                ){}
    }

    export class New {
        constructor(public id: number,
                    public title: string,
                    public body: string,
                    public photo: string,
                    public status: string,
                    ){}
        }

    export class Quote {
        constructor(
                    public name:  string,
                    public email:  string,
                    public phone:  string,
                    public company_name:  string,
                    public shipment_type:   string,
                    public status:  string,
                    public shipment_cost_europe:  string,
                    public shipment_cost_by_sea:  string,
                    public shipment_cost_libya:  string,
                    public quantity:  string,
                    public goods_type:  string,
                    public origin_address:  string,
                    public destination_address:  string,
                    public shipment_invoice:  string,
                    public total_weight:  string,
                    public container_type:   string,
                    public container_size:   string,
                    public length:  string,
                    public width:  string,
                    public height:  string,
                    public roro_type: string,
                    public roro_model:  string,
                    public roro_year:  string,
                    public roro_length:  string,
                    public roro_width:  string,
                    ){}
        }


export class MenuItem {
    constructor(public id: number,
                public name: string,
                public description: string,
                public price: number,
                public image: MenuItemImage,
                public discount: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public availibilityCount: number,
                public cartCount: number,
                public weight: number,
                public isVegetarian: boolean,
                public categoryId: number){}
}

export class MenuItemImage {
    constructor(public small: string,
                public medium: string,
                public big: string){ }
}

export class Category {
    constructor(public id: number,
                public name: string,
                public description: string){ }
}   

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number | null,
                public nextPage: number | null,
                public total: number,
                public totalPages: number){ }
}

