<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span (click)="emailClicked('info@bluelinessrl.com')" class="px-1">info@bluelinessrl.com</span>
</span>

<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>

<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-sm>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span style="direction: ltr;" class="px-1">+393203505655 | +393203033251</span>
</span>

<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>

<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1">Via Domenico De Roberto N' 122. Napoli, Italy</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>

