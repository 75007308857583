<div class="section mt-1">
    <div class="px-1">
        <div class="theme-container">
            <!-- <h1 class="section-title">Blue Lines</h1>  -->
            <!-- <p class="section-desc text-muted">Modern And Trusted Logistics Company</p> -->
            <mat-card class="p-0 o-hidden" fxLayout="row wrap"> 
                <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="50">
                    <img id="photo" [src]="this.new.photo" alt="about" class="mw-100 d-block">
                </div> 
                <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="50" class="py-3 px-4">
                    <h1 class="secondary-font primary-color mb-2">{{this.new.title}}</h1>
                    <hr>
                    <!-- <p> One-Stop-Solution to Meet All of Your Logistics Requirements. </p> -->
                    <p style="font-size: 20px;text-align: justify; text-justify: inter-word;">{{this.new?.body}}</p>
                    <!-- <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pt-3">
                        <img src="assets/images/others/signature.png" alt="signature"> 
                        <a mat-raised-button routerLink="/about" color="primary" class="uppercase">Read more</a>
                    </div>  -->
                </div>  
            </mat-card>
        </div>
    </div>   
</div>
