import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { QuoteService } from '../../services/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar'; 

@Component({
  selector: 'app-get-quote-form',
  templateUrl: './get-quote-form.component.html',
  styleUrls: ['./get-quote-form.component.scss']
})
export class GetQuoteFormComponent implements OnInit{
  container : any;
  type1 = true;
  type2 = false;
  type3 = false;
  type4 = false;
  default_form = this.formBuilder.group({
    name:  [''],
    email:  [''],
    // phone:  [''],
    phone: ['',[Validators.required, Validators.pattern("^[0-9]*$")]], 
    company_name:  [''],
    shipment_type:  [''],

    quantity:  [1],
    goods_type:  [''],
    origin_address:  [''],
    destination_address:  [],
    shipment_invoice:  [''],
    total_weight:  [''],
    container_type:   [''],
    container_size:   [''],
    length:  [''],
    width:  [''],
    height:  [''],
    roro_type: [''],
    roro_model:  [''],
    roro_year:  [''],
    roro_length:  [''],
    roro_width:  [''],

    type_of_goods_others:  [''], ///////
  });

  public form!: FormGroup;
  refNo : any;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter(); 
  constructor(
    public formBuilder: FormBuilder,
    public snackBar: MatSnackBar, 
    public quoteService: QuoteService
  ) { }

  async ngOnInit() { 
    this.default_form.value.destination_address = 'tripoli';
    this.container = '{"type":"general","size":"20"}';
    this.form = this.default_form;
    this.showSection(1);
  }


  reload(){
    window.location.reload();
  }

  public async submit(){
    if(!this.form.valid){
      this.snackBar.open( 'INVALID FORM' , '×', 
        { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      return;
    }

    if(this.form.value.shipment_type == 'pallet'){
      (!this.form.value.goods_type) ? this.form.controls['goods_type'].setErrors({'incorrect': true}) : null;
      (!this.form.value.total_weight) ? this.form.controls['total_weight'].setErrors({'incorrect': true}) : null;
      (!this.form.value.length) ? this.form.controls['length'].setErrors({'incorrect': true}) : null;
      (!this.form.value.height) ? this.form.controls['height'].setErrors({'incorrect': true}) : null;
      (!this.form.value.width) ? this.form.controls['width'].setErrors({'incorrect': true}) : null;
      if(!this.form.value.total_weight || 
        !this.form.value.length || 
        !this.form.value.height || 
        !this.form.value.width ||
        !this.form.value.goods_type){
          this.snackBar.open('INVALID FORM', '×', 
          { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return;
      }
    }

    if(this.form.value.shipment_type == 'boxes'){
      (!this.form.value.goods_type) ? this.form.controls['goods_type'].setErrors({'incorrect': true}) : null;
      (!this.form.value.total_weight) ? this.form.controls['total_weight'].setErrors({'incorrect': true}) : null;
      (!this.form.value.length) ? this.form.controls['length'].setErrors({'incorrect': true}) : null;
      (!this.form.value.height) ? this.form.controls['height'].setErrors({'incorrect': true}) : null;
      (!this.form.value.width) ? this.form.controls['width'].setErrors({'incorrect': true}) : null;
      if(!this.form.value.total_weight || 
        !this.form.value.length || 
        !this.form.value.height || 
        !this.form.value.goods_type ||
        !this.form.value.width){
          this.snackBar.open('INVALID FORM', '×', 
          { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return;
      }
    } 

    if(this.form.value.shipment_type == 'ro-ro'){

      (!this.form.value.roro_type) ? this.form.controls['roro_type'].setErrors({'incorrect': true}) : null;
      (!this.form.value.roro_model) ? this.form.controls['roro_model'].setErrors({'incorrect': true}) : null;
      (!this.form.value.roro_year) ? this.form.controls['roro_year'].setErrors({'incorrect': true}) : null;
      (!this.form.value.roro_width) ? this.form.controls['roro_width'].setErrors({'incorrect': true}) : null;
      (!this.form.value.roro_length) ? this.form.controls['roro_length'].setErrors({'incorrect': true}) : null;
      
      if(!this.form.value.roro_type || 
        !this.form.value.roro_model || 
        !this.form.value.roro_year || 
        !this.form.value.roro_width ||
        !this.form.value.roro_length){
          this.snackBar.open('INVALID FORM', '×', 
          { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return;
      }
    } 
    if(this.form.value.shipment_type == 'container'){
      this.form.controls['container_size'].setValue(JSON.parse(this.container).size);
      this.form.controls['container_type'].setValue(JSON.parse(this.container).type);
      (!this.form.value.goods_type) ? this.form.controls['goods_type'].setErrors({'incorrect': true}) : null;
      if(!this.form.value.goods_type){
        this.snackBar.open('INVALID FORM', '×', 
        { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return;
      }
    }

    if(this.form.valid){
      try {
        const result = await this.quoteService.createQuote(this.form.value);
            if (result.code !== '0000') {
              this.snackBar.open(result.error.error, '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });          
            } else {
              this.refNo = result.data.id;
              this.form.reset();
              this.form = this.default_form;
              // this.form.updateValueAndValidity();
                // this.snackBar.open('Your request has been submitted, we will get back to you by email or Whatsapp', '×', 
                this.snackBar.open('Your request has been submitted,we will be in touch shortly', '×', 

                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            }
      } catch (error) {
        this.snackBar.open('Error', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
          console.log('Error', error);
      }
    } 
  }

  showSection(event: any){
    if (event == 3) {
      this.form.controls['shipment_type'].setValue('pallet');
      this.type1 = false;
      this.type2 = false;
      this.type3 = true;
      this.type4 = false;
    } else if (event == 2){
      this.form.controls['shipment_type'].setValue('ro-ro');
      this.type1 = false;
      this.type2 = true;
      this.type3 = false;
      this.type4 = false;
    } else if (event == 1){
      this.form.controls['shipment_type'].setValue('container');
      this.container = '{"type":"general","size":"20"}';
      this.type1 = true;
      this.type2 = false;
      this.type3 = false;
      this.type4 = false;
    } else {
      this.form.controls['shipment_type'].setValue('boxes');
      this.type1 = false;
      this.type2 = false;
      this.type3 = false;
      this.type4 = true;
    }    
  }

}
