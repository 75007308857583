import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings, Settings } from '../../app.settings';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {  
  public settings:Settings;
  constructor(public translateService: TranslateService,public appSettings:AppSettings) {
    this.settings = this.appSettings.settings
   }

  ngOnInit() { } 

  public changeLang(lang:string){ 
    this.translateService.use(lang);    
    if (lang === 'ly') {
      this.settings.rtl = true;
      localStorage.setItem('lang_code', '2')
    } else if (lang === 'en'){
      this.settings.rtl = false;
      localStorage.setItem('lang_code', '1') 
    } else if (lang === 'it'){
      localStorage.setItem('lang_code', '3') 
      this.settings.rtl = false;
    }
  } 


}
