import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  quote_url = `${environment.api_url}/quotes`;

  constructor(private baseService: BaseService) {}

  createQuote(Quote: any): Promise<any> {
    try {
      return this.baseService
        .POST(`${this.quote_url}`, Quote)
        .toPromise();
    } catch (error) {
      return observableThrowError(error).toPromise();
    }
  }

  listQuotes(options: any): Promise<any> {
    try {
      return this.baseService
        .GET(
          `${this.quote_url}?filter=` +
            options.filter +
            '&sort=' +
            options.sort +
            '&order=' +
            options.order +
            '&pageSize=' +
            options.pageSize +
            '&pageIndex=' +
            options.pageIndex
        )
        .toPromise();
    } catch (error) {
      return observableThrowError(error).toPromise();
    }
  }

  getQuote(id: any): Promise<any> {
    try {
      return this.baseService.GET(`${this.quote_url}/${id}`).toPromise();
    } catch (error) {
      return observableThrowError(error).toPromise();
    }
  }

  updateQuote(Quote: any, id:any): Observable<any> {
    try {
        return this.baseService.UPDATE(
            `${this.quote_url}/${id}`,
            Quote
        );
    } catch (error) {
        return observableThrowError(error);
    }
}

}
