<div *ngIf="this.refNo" fxLayout="row wrap" class="row"> 
    <div class="card">

          <div fxFlex="100">
            <h1>{{ 'Success' | translate }}</h1> 
            <p>{{ 'Your request has been submitted' | translate }}</p>
            <br/>
            <p> {{ 'we will be in touch shortly' | translate }} !</p>

            <hr>
            <p>{{ 'Your reference number is' | translate }} :  #{{this.refNo}}</p>
  
            <br>
            <br>
            <a mat-raised-button color="primary" (click)="reload()" routerLink="/get-quote">{{ 'GET_A_QUOTE' | translate }}</a> 
          </div>

        </div>
</div>
<br>


<form *ngIf="!this.refNo" [formGroup]="form" (ngSubmit)="submit()" fxLayout="row wrap" class="reservation-form" autocomplete="off">
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-radio-group formControlName="shipment_type" aria-label="Select an option">
            <mat-radio-button value="container" (change)="showSection(1)">{{ 'Container' | translate }}</mat-radio-button>
            <mat-radio-button value="ro-ro" (change)="showSection(2)">{{ 'Truck / Car (Ro-Ro)' | translate }}</mat-radio-button>
            <mat-radio-button value="pallet" (change)="showSection(3)">{{ 'Groupage (LCL-pallet)' | translate }}</mat-radio-button>
            <mat-radio-button value="boxes" (change)="showSection(4)">{{ 'Groupage (LCL-boxes)' | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <br>
    <br>
    <br>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Full Name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Your Full Name' | translate }}" formControlName="name" required>
            <mat-icon matSuffix class="text-muted">person</mat-icon>
            <mat-error *ngIf="form.controls.name.errors?.required">{{ 'Full Name is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Phone Number' | translate }}</mat-label>
            <!-- <input matInput placeholder="Your Phone Number" formControlName="phone" required appPhoneMask> -->
            <input matInput placeholder="{{ 'Your Phone Number' | translate }}" type="text" formControlName="phone" required>
            <mat-icon matSuffix class="text-muted">call</mat-icon>
            <mat-error *ngIf="form.controls.phone.errors?.required">{{ 'Phone number is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Your Email' | translate }}" formControlName="email" type="email" required>
            <mat-icon matSuffix class="text-muted">email</mat-icon>
            <mat-error *ngIf="form.controls.email.errors?.required">{{ 'Email is required' | translate }}</mat-error>
            <mat-error *ngIf="form.controls.email.hasError('invalidEmail')">{{ 'Invalid email address' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Company name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Company name' | translate }}" formControlName="company_name">
            <mat-icon matSuffix class="text-muted">domain</mat-icon>
            <mat-error *ngIf="form.controls.company_name.errors?.required">{{ 'Company name is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Origin address' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Please enter the Post Code and City of origin' | translate }}" formControlName="origin_address" required>
            <mat-icon matSuffix class="text-muted">call_made</mat-icon>
            <mat-error *ngIf="form.controls.origin_address.errors?.required">{{ 'Origin address is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Destination address' | translate }}</mat-label>
            <mat-select formControlName="destination_address" required>
                <mat-option value='tripoli'>{{ 'Tripoli' | translate }}</mat-option>
                <mat-option value='misurata'>{{ 'Misurata' | translate }}</mat-option>
                <mat-option value='benghazi'>{{ 'Benghazi' | translate }}</mat-option>
                <mat-option value='sabha'>{{ 'Sabha' | translate }}</mat-option>
            </mat-select>
            <mat-icon matSuffix class="text-muted">call_received</mat-icon>
            <mat-error *ngIf="form.controls.destination_address.errors?.required">{{ 'Destination address is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type1" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Container type' | translate }}</mat-label>
            <mat-select [(value)]="container">
                <mat-option [value]='{"type":"general","size":"20"}'>{{ '20 General' | translate }}</mat-option>
                <mat-option value='{"type":"reefer","size":"20"}'>{{ '20 Reefer' | translate }}</mat-option>
                <mat-option value='{"type":"general","size":"40"}'>{{ '40 General' | translate }}</mat-option>
                <mat-option value='{"type":"reefer","size":"40"}'>{{ '40 Reefer' | translate }}</mat-option>
                <mat-option value='{"type":"high-cube","size":"20"}'>{{ '20 high-cube' | translate }}</mat-option>
                <mat-option value='{"type":"high-cube","size":"40"}'>{{ '40 high-cube' | translate }}</mat-option>
                <mat-option value='{"type":"open-top","size":""}'>{{ 'Open top' | translate }}</mat-option>
                <mat-option value='{"type":"flat","size":""}'>{{ 'flat' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="type1 || type2 || type3 || type4" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Quantity' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Quantity' | translate }}" type="number" formControlName="quantity" onlyNumber required>
            <mat-icon matSuffix class="text-muted">format_list_numbered</mat-icon>
            <mat-error *ngIf="form.controls.quantity.errors?.required">{{ 'Quantity Number is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type3 || type4 || type1" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Type of goods' | translate }}</mat-label>
            <mat-select formControlName="goods_type" required>
                <mat-option value="parfum">{{ 'Parfum' | translate }}</mat-option>
                <mat-option value="clothing">{{ 'Clothing' | translate }}</mat-option>
                <mat-option value="general">{{ 'General' | translate }}</mat-option>
                <mat-option value="others">{{ 'Other' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="this.form.value.goods_type === 'others'" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Other' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Other...' | translate }}" formControlName="type_of_goods_others">
            <mat-icon matSuffix class="text-muted">email</mat-icon>
            <mat-error *ngIf="form.controls.type_of_goods_others.errors?.required">{{ 'Other is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type2" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Ro-Ro Type' | translate }}</mat-label>
            <mat-select formControlName="roro_type">
                <mat-option value="car">{{ 'Car' | translate }}</mat-option>
                <mat-option value="truck">{{ 'Truck' | translate }}</mat-option>
                <mat-option value="heavy">{{ 'Heavy Truck' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="type2" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'RoRo model' | translate }}</mat-label>
            <input matInput placeholder="{{ 'RoRo model' | translate }}" formControlName="roro_model">
            <mat-icon matSuffix class="text-muted">directions_bus</mat-icon>
            <mat-error *ngIf="form.controls.roro_model.errors?.required">{{ 'RoRo model is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type2" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'RoRo year' | translate }}</mat-label>
            <input matInput placeholder="{{ 'RoRo year' | translate }}" formControlName="roro_year">
            <mat-icon matSuffix class="text-muted">calendar_today</mat-icon>
            <mat-error *ngIf="form.controls.roro_year.errors?.required">{{ 'RoRo year is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type2" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'RoRo length' | translate }}</mat-label>
            <input matInput placeholder="{{ 'RoRo length' | translate }}" formControlName="roro_length">
            <mat-icon matSuffix class="text-muted">photo_size_select_small</mat-icon>
            <mat-error *ngIf="form.controls.roro_length.errors?.required">{{ 'RoRo length is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type2" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'RoRo width' | translate }}</mat-label>
            <input matInput placeholder="{{ 'RoRo width' | translate }}" formControlName="roro_width">
            <mat-icon matSuffix class="text-muted">photo_size_select_small</mat-icon>
            <mat-error *ngIf="form.controls.roro_width.errors?.required">{{ 'RoRo width is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type3 || type4" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Length' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Length in cm' | translate }}" formControlName="length" type="number" onlyNumber>
            <mat-icon matSuffix class="text-muted">photo_size_select_small</mat-icon>
            <mat-error *ngIf="form.controls.length.errors?.required">{{ 'Length is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type3 || type4" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Width' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Width in cm' | translate }}" formControlName="width" type="number" onlyNumber onlyNumber>
            <mat-icon matSuffix class="text-muted">photo_size_select_small</mat-icon>
            <mat-error *ngIf="form.controls.width.errors?.required">{{ 'Width is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type3 || type4" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Height' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Height in cm' | translate }}" formControlName="height" type="number" onlyNumber>
            <mat-icon matSuffix class="text-muted">photo_size_select_small</mat-icon>
            <mat-error *ngIf="form.controls.height.errors?.required">{{ 'Height is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="type3 || type4" fxFlex="100" fxFlex.gt-sm="33.3" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Total weight' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Total weight in kg' | translate }}" formControlName="total_weight" onlyNumber>
            <mat-icon matSuffix class="text-muted">monitor_weight</mat-icon>
            <mat-error *ngIf="form.controls.total_weight.errors?.required">{{ 'Total weight is required' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <!-- <div fxFlex="100" class="px-3 mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Details</mat-label>
            <textarea matInput placeholder="Your Shipment Details" formControlName="details" rows="6"></textarea>
            <mat-error *ngIf="form.controls.details.errors?.required">Details is required</mat-error>
        </mat-form-field>
    </div> -->
    <div class="w-100 py-2 text-center">
        <button mat-raised-button color="primary" type="submit" class="uppercase">{{ 'Get a Quote' | translate }}</button>
    </div>
</form>
<div class="reservation-form-footer primary-color" style="direction: ltr;">
    {{ 'You can also call: +393203505655 | +393203033251 to get a quote' | translate }}
</div>